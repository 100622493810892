import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Button, } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Table from 'react-bootstrap/Table'
import axios from 'axios'
import { useEffect } from 'react';
/* import env from 'dotenv' */

/* env.config()
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER
const store = process.env.REACT_APP_STORE_ID */
const OnBoardStoreEditScreen = ({ match }) => {
	const invoiceId = match.params.id

	const [name, setName] = useState('')
	const [address, setAddress] = useState('')
	const [city, setCity] = useState('')
	const [company, setCompany] = useState('')
	const [country, setCountry] = useState('')
	const [pinCode, setPinCode] = useState('')
	const [gst, setGst] = useState('')
	const [logo, setLogo] = useState('')
	const [gstRates, setGstRates] = useState()



	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo, loading, error } = userLogin

	const productUpdate = useSelector((state) => state.productUpdate)
	const { loading: loadingUpdate, error: errorUpdate/* , success: successUpdate */ } = productUpdate



	useEffect(() => {

		axios.get(`/api/config/${invoiceId}`)
			.then((res) => {
				const Data = res.data
				setName(Data.name)
				setAddress(Data.address)
				setCity(Data.city)
				setCompany(Data.company)
				setCountry(Data.country)
				setPinCode(Data.pinCode)
				setGst(Data.gstNumber)
				setLogo(Data.logo)
				setGstRates(Data.gstRates)

			})
	}, [invoiceId])

	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${userInfo.token}`,
		},
	}

	const submitHandler = (e) => {
		e.preventDefault()
		const data = {
			"name": name,
			"address": address,
			"city": city,
			"company": company,
			"country": country,
			"pinCode": pinCode,
			"gstNumber": gst,
			"logo": logo

		}
		axios.put(`/api/config/${invoiceId}`, data, config)
		clearImmediate();
	}

	/* const addObjectToArray = obj => {
		console.log("double click");
		const data = {
			"name": name,
			"address": address,
			"city": city,
			"company": company,
			"country": country,
			"pinCode": pinCode,
			"gstNumber": gst,
			"logo": logo
		}
		axios.put(`/api/config/${invoiceId}`, data, config)

		setGstRates(current => [...current, obj]);
	}; */


	return (
		<div>
			{loadingUpdate && <Loader />}
			{errorUpdate && <Message variant='warning'>{errorUpdate}</Message>}
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='warning'>{error}</Message>
			) : (
				<>
					<Button variant='secondary'>
						<Link to='/admin/invoices' style={{ color: "white" }}>Go back</Link>
					</Button>
					<Form onSubmit={submitHandler}>
						<Row className="mb-1">
							<h4 style={heading}> Edit Store Details</h4>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="Name">
									<Form.Label>Name</Form.Label>
									<Form.Control type='text' placeholder="Invoice Number" value={name} onChange={(e) => setName(e.target.value)} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="Address">
									<Form.Label>Address</Form.Label>
									<Form.Control type='text' placeholder="address" value={address} onChange={(e) => setAddress(e.target.value)} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="City">
									<Form.Label>City</Form.Label>
									<Form.Control type='text' placeholder="address" value={city} onChange={(e) => setCity(e.target.value)} />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="Company">
									<Form.Label>Company</Form.Label>
									<Form.Control type='text' placeholder="Invoice Number" value={company} onChange={(e) => setCompany(e.target.value)} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="Country">
									<Form.Label>Country</Form.Label>
									<Form.Control type='text' placeholder="address" value={country} onChange={(e) => setCountry(e.target.value)} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="PinCode">
									<Form.Label>PinCode</Form.Label>
									<Form.Control type='text' placeholder="address" value={pinCode} onChange={(e) => setPinCode(e.target.value)} />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="gstNumber">
									<Form.Label>gst Number</Form.Label>
									<Form.Control type='text' placeholder="Invoice Number" value={gst} onChange={(e) => setGst(e.target.value)} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="Logo">
									<Form.Label>Logo</Form.Label>
									<Form.Control type='text' placeholder="address" value={logo} onChange={(e) => setLogo(e.target.value)} />
								</Form.Group>
							</Col>
						</Row>
						<h3>Gst Rates</h3>
						{gstRates && (
							<>
								<Table striped>
									<thead>
										<tr>
											<th>ID</th>
											<th>category</th>
											<th>rate</th>
										</tr>
									</thead>
									<tbody>
										{gstRates.map((rate, i) => {
											return [
												<tr key={i} >
													<td>{rate._id}</td>
													<td>{rate.category}</td>
													<td>{rate.rate}</td>
												</tr>]
										})}
									</tbody>
								</Table>
							</>
						)}
						<Button className='mt-2' variant="primary" type="submit">
							Submit
						</Button>
					</Form>
				</>)
			}


		</div >
	)
}

export default OnBoardStoreEditScreen


const heading = {
	textAlign: 'center',
	color: '#000'
}