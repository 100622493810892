import React, { useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'

const SearchAdmin = ({ history }) => {
	const [keyword, setKeyword] = useState('')


	const submitHandler = (e) => {
		e.preventDefault()
		if (keyword.trim()) {
			history.push(`/admin/productlist/search/${keyword}`)
		}
		else {
			history.push('/')
		}
	}

	return (
		<Form onSubmit={submitHandler} inline>
			<Row>
				<Col xs={8} md={11} >
					<Form.Control type='text' name='q' id='searchBox' onChange={(e) => setKeyword(e.target.value)} placeholder='Search products...' className='mr-sm-2 ml-sm-5'></Form.Control>
				</Col>
				<Col xs={1} md={1} >
					<button type='submit' className='white_button' id='search'>
						Search
					</button>
				</Col>
			</Row>
		</Form>
	)
}

export default SearchAdmin
