import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, ListGroup, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { addToCart, addBundleToCart, removeFromCart, addToCartUsingSku, addToCartAdmin } from "../actions/cartActions";
import { listAllProducts } from "../actions/productActions";
import { createOrderAdmin, printOrder } from "../actions/orderActions";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import PhoneInput from "react-phone-number-input";
import {
  CLOTHING,
  ELECTRONICS,
  FOOTWEAR,
  FURNITURE,
  SPORTS,
  GROCERY,
  FOOD,
  BOOKS,
  TOYS,
} from "../constants/categoryConstants";
import Message from "../components/Message";
import Loader from "../components/Loader";

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id;
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const marketplaceId = localStorage.getItem('marketPlaceAddress') ? JSON.parse(localStorage.getItem('marketPlaceAddress')).marketplaceId : store._id;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const [sku, setSku] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [orderType, setOrderType] = useState('pos');
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [showError, setShowError] = useState('false')




  const getGST = (category) => {
    switch (category) {
      case ELECTRONICS:
        return 18;
      case CLOTHING:
        return 12;
      case GROCERY:
        return 5;
      case FOOD:
        return 5;
      case FOOTWEAR:
        return 5;
      case SPORTS:
        return 12;
      case FURNITURE:
        return 12;
      case BOOKS:
        return 5;
      case TOYS:
        return 12;
      default:
        return 5;
    }
  };



  const calculateTax = (item) => {
    const GST = getGST(item.category);
    return item.price - (item.price * 100) / (100 + GST);
  };

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const orderButtonRef = useRef(null);
  const printButtonRef = useRef(null);
  const { cartItems, error, loading } = cart;

  useEffect(() => {
    if (productId && type === "bundle") {
      dispatch(addBundleToCart(productId, qty));
    } else {
      if (productId) {
        dispatch(addToCart(productId, qty));
      }
    }

  }, [dispatch, productId, qty, type, sku]);

  const productList = useSelector((state) => state.productList);
  const { products } = productList;


  const orderCreate = useSelector((state) => state.orderCreate);
  const { success, order } = orderCreate;

  const handleKeyPress = useCallback((event) => {

    if (event.altKey && event.key === 'o') {
      console.log(`Key pressed: ${event.key}`);
      orderButtonRef.current.click();
    }

    else if (event.altKey && event.key === 'p') {
      console.log(`Key pressed: ${event.key}`);
      printButtonRef.current.click();
    }

  }, []);

  useEffect(() => {

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000); // Show error for 3 seconds

      return () => clearTimeout(timer); // Clear timeout if the component unmounts
    }
  }, [error]);



  const addToCartUsingSkuHandler = (e) => {
    e.preventDefault();
    console.log(sku.length);
    console.log("adding it to cart");
    dispatch(addToCartUsingSku(sku, qty));
    setSku("");
  };

  const addToCartUsingSkuFromSearch = (productSKU) => {

    console.log(sku.length);
    console.log("adding it to cart");
    dispatch(addToCartUsingSku(productSKU, qty));
  };

  const getProducts = useCallback(() => {
    console.log(name);
    console.log("getting products");
    dispatch(listAllProducts(name));
    setSku("");
  }, [dispatch, name]);


  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(cart?.cartItems?.reduce((acc, item) => acc + item.price * item.qty, 0));
  cart.shippingPrice = addDecimals(cart?.itemsPrice > 50 ? 0 : 0);
  cart.taxPrice = cart?.cartItems?.reduce((acc, item) => acc + calculateTax(item), 0);
  cart.totalPrice = Number(cart.itemsPrice) + Number(cart.shippingPrice);

  const placeOrderHandler = () => {
    const orderData = {
      orderItems: cart.cartItems,
      shippingAddress: {
        phone: phoneNumber ? phoneNumber : 9999999999,
        address: "Chitguppa",
        city: "Bidar",
        pinCode: "585412",
        country: "India",
      },
      paymentMethod: paymentMethod,
      itemsPrice: cart.itemsPrice,
      shippingPrice: cart.shippingPrice,
      taxPrice: cart.taxPrice,
      totalPrice: cart.totalPrice,
      phone: phoneNumber ? phoneNumber : 9999999999,
      orderType
    };
    dispatch(createOrderAdmin(orderData, marketplaceId))
      .then((response) => {
        if (success) {
          focusOnSkuInput();
        } else {
          console.error("Error placing order:", response.payload.error);
        }
      })
      .catch((error) => {
        console.error("Error placing order:", error);
      });
  };


  const printOrderReceipt = () => {
    if (success) {
      dispatch(printOrder(order));
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
      focusOnSkuInput();
    }
  };


  const focusOnSkuInput = () => {
    const skuInput = document.getElementById('sku');

    if (skuInput) {
      skuInput.focus();
    } else {
      console.warn("SKU input element with ID 'sku' not found.");
    }
  }


  useEffect(() => {


    if (success) {
      dispatch({
        type: CART_CLEAR_ITEMS,
      });
      localStorage.removeItem("cartItems");
      setPhoneNumber(null);
    }
    // eslint-disable-next-line
  }, [history, success]);
  return (
    <div style={{ background: '#EFF1EE', marginLeft: '-5em', marginTop: '-5em', marginRight: '-4em', paddingLeft: "3em", paddingTop: '5em', height: '63em' }}>   <Row>
      {loading ? (
        <Loader />
      ) : error && showError ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>

          <Col md={6}>

            <Form className="mt-4" onSubmit={addToCartUsingSkuHandler} inline>
              <h3 style={{ color: "black" }}>Product SKU</h3>
              <Row>
                <Col>
                  <Form.Control
                    style={{ width: "16rem" }}
                    type="text"
                    autoFocus
                    value={sku}
                    name="sku"
                    id="sku"
                    onChange={(e) => setSku(e.target.value)}
                    placeholder="Add Product using sku..."
                    className="mr-sm-2 ml-sm-5"
                  ></Form.Control>
                </Col>
                <Col>

                  <Form.Control
                    as="select"
                    aria-label="Order Type"
                    value={orderType}
                    onChange={(e) => setOrderType(e.target.value)}
                  >
                    <option value="pos">POS</option>
                    <option value="invoice">Invoice</option>
                    <option value="po">PO</option>
                    <option value="request">RR</option>
                    <option value="return">Return</option>
                  </Form.Control>
                </Col>
              </Row>

            </Form>
            {cartItems?.length === 0 ? (
              <h4>
                Your cart is empty<Link to="/"> Go to homepage</Link>
              </h4>
            ) : (
              <ListGroup variant="flush">
                <Row
                  style={{
                    textAlign: "center",
                    marginBottom: "15px",
                    fontWeight: "600",
                    color: "black",
                    marginRight: "4em",
                  }}
                >
                  {/*    <Col md={1}>Photo</Col> */}
                  <Col md={4}>Item</Col>
                  <Col md={2}>MRP</Col>
                  <Col md={2}>Price</Col>
                  <Col md={2}>Quantity</Col>
                  <Col md={1}>Delete</Col>
                </Row>
                {cartItems.map((item) => (
                  <ListGroup className='pb-2' key={item.product}>
                    <Row style={{ textAlign: "center", marginRight: "4em" }}>
                      {/* <Col md={1}>
                   <Image src={item.image} alt={item.name} fluid></Image>
                 </Col> */}
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          value={item.name}
                          onChange={(e) =>
                            dispatch(addToCartAdmin(item.product, item.qty, item.price, item.mrp, e.target.value, item))
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="number"
                          value={item.mrp}
                          onChange={(e) =>
                            dispatch(addToCartAdmin(item.product, item.qty, item.price, e.target.value, item.name, item))
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="number"
                          value={item.price}
                          onChange={(e) =>
                            dispatch(addToCartAdmin(item.product, item.qty, e.target.value, item.mrp, item.name, item))
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Form.Control
                          type="number"
                          value={item.qty}
                          onChange={(e) => dispatch(addToCartAdmin(item.product, e.target.value, item.price, item.mrp, item.name, item))}
                        />
                      </Col>
                      <Col md={1} tabIndex={5}>
                        <Button tabIndex={5}
                          type="button"
                          variant="light"
                          id="delete"
                          onClick={() => removeFromCartHandler(item.product)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup>
                ))}
              </ListGroup>
            )}

            <Form style={{ marginTop: "1.2rem" }}>
              <Form.Group controlId="phoneNumber">
                <Form.Label className="d-flex align-items-center">
                  <h4>Customer mobile number</h4>
                  <span className="text-danger ml-6">*</span>
                </Form.Label>
                <Row>
                  <Col>
                    <PhoneInput
                      defaultCountry="IN"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      placeholder="10 digit mobile number"
                    />
                  </Col>
                  <Col>

                    <Form.Control
                      as="select"
                      aria-label="Order Type"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                      <option value="cash">Cash</option>
                      <option value="upi">UPI</option>
                      <option value="check">Check</option>
                    </Form.Control>


                  </Col>
                  <Col>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click or press Alt+o to order
                        </Tooltip>
                      }
                    >
                      <Button
                        ref={orderButtonRef}
                        type="button"
                        variant="secondary"
                        id="proceed"
                        disabled={cartItems?.length === 0}
                        onClick={placeOrderHandler}
                      >
                        Order
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Click or press Alt+o to order
                        </Tooltip>
                      }
                    >
                      <Button ref={printButtonRef} type="button" variant="secondary" onClick={printOrderReceipt} disabled={!success}>
                        Print reciept
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Form.Group>
            </Form>


            <ListGroup className="pt-4">
              <ListGroup.Item>
                <h5>TotalItems : {cartItems.reduce((acc, item) => acc + item.qty, 0)}</h5>
                Total price: ₹{" "}
                {cartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </ListGroup.Item>
            </ListGroup>


          </Col>
          <Col className="px-4">

            <Form className="mt-4" onSubmit={getProducts} inline>

              <h3 style={{ color: "black" }}>Search products</h3>



              <Row>
                <Col xs={8} md={11} >
                  <Form.Control type='text' id='searchBox' onChange={(e) => setName(e.target.value)} placeholder='Search products...' className='mr-sm-2 ml-sm-5'></Form.Control>
                </Col>
                <Col xs={1} md={1} >
                  <button onClick={getProducts} className='white_button' id='search' >
                    Search
                  </button>
                </Col>

              </Row>
            </Form>

            {name && (<>
              <Row className="tableHead mobileHiddenList listSize">
                <Col md={2}>Image</Col>
                <Col md={3}>NAME </Col>
                <Col md={1}>PRICE </Col>
                <Col md={2}>SKU</Col>
                <Col md={2}>CATEGORY </Col>
                <Col md={1}>BRAND </Col>
                <Col md={1}>ADD</Col>
              </Row>
              <div className=" mobileHiddenList ">
                {products.map((product) => (
                  <Row key={product._id}>
                    <Col md={2}>
                      <img
                        className="fitImageList"
                        src={
                          product.images[0]
                            ? product.images[0]
                            : "./img/sample.jpg"
                        }
                        alt="img"
                      />
                    </Col>
                    <Col className="listSize" md={3}>
                      {product.name}
                    </Col>
                    <Col className="listSize tableData" md={1}>
                      {product.price}
                    </Col>
                    <Col className="listSize tableData" md={2}>
                      {product.sku}
                    </Col>
                    <Col className="listSize tableData" md={2}>
                      {product.category}
                    </Col>
                    <Col className="listSize tableData" md={1}>
                      {product.brand}
                    </Col>
                    <Col className="tableData" md={1}>
                      <Button
                        variant="light"
                        style={{ color: "blue" }}
                        className="btn-sm"
                        onClick={() => addToCartUsingSkuFromSearch(product.sku)}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                ))}
              </div>
            </>)}
          </Col>

        </>
      )}
    </Row></div>

  );
};

export default CartScreen;
