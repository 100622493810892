import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import SearchAdmin from "../components/SearchAdmin";
import { useCSVReader } from "react-papaparse";
import {
  listAllProducts,
  deleteProduct,
  createProduct,
  createSampleProduct,
} from "../actions/productActions";
import { PRODUCT_CREATE_RESET } from "../constants/productConstants";
import { useState } from "react";
import { parse } from 'date-fns';

const ProductListScreen = ({ history, match }) => {
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;
  const [visible, setVisible] = useState(true);
  const dispatch = useDispatch();
  const { CSVReader } = useCSVReader();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, pages, page } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (!(userInfo.role.includes("admin") || userInfo.role.includes("Admin"))) {
      history.push("/login");
    }
    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listAllProducts(keyword, pageNumber));
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    keyword,
    pageNumber,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are You Sure")) {
      dispatch(deleteProduct(id));
    }
  };
  const createProductHandler = (product) => {
    dispatch(createProduct(product));
  };

  const createSampleProductHandler = () => {
    dispatch(createSampleProduct());
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(false);
    } else if (scrolled <= 0) {
      setVisible(true);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div style={{ background: '#EFF1EE', marginLeft: '-5em', marginTop: '-5em', marginRight: '-4em', paddingLeft: "3em", paddingTop: '5em', height: '150em' }} >
      <Row>
        <Col className="mobileHiddenList">
          <Button
            variant="dark"
            className="float-end"
            onClick={scrollToBottom}
            style={{ display: visible ? "inline" : "none" }}
          >
            Go Down <i className="fas fa-arrow-circle-down fs-0.5"></i>
          </Button>
        </Col>
      </Row>
      <Row className="alighn-items-center mb-2">
        <Col md={3}>
          <Button
            className="my-3"
            variant="dark"
            onClick={createSampleProductHandler}
          >
            <i className="fas fa-plus"></i> Create product
          </Button>
        </Col>
        <Col xs={12} sm={12} md={4} style={{ marginRight: "1rem" }}>
          <Route render={({ history }) => <SearchAdmin history={history} />} />
        </Col>
        <Col className="mobileHiddenList">
          <LinkContainer to={`/admin/allproducts`}>
            <Button className="my-3 float-end" variant="dark">
              Download products csv
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="warning">{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="warning">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <div className="mobileHiddenList">
            <CSVReader
              onUploadAccepted={(results) => {
                console.log("---------------------------");
                console.log(results);
                const products = results.data;

                products.shift();

                console.log(products);

                products.map((product) => {
                  const name = product[0] || '';
                  const images = product[1] || [];
                  const description = product[2] || '';
                  const brand = product[3] || '';
                  const sku = product[4] || '';
                  const category = product[5] || '';
                  const price = product[6] || 0;
                  const mrp = product[7] || 0;
                  const cost = product[8] || 0;
                  const status = product[9] || '';
                  const countInStock = product[10] || 0;
                  const expiryDate = product[11]
                    ? parse(product[11], 'dd/MM/yyyy', new Date())
                    : new Date('2025-12-26');
                  const taxpercentage = product[12] || 0;
                  const size = product[13] || "1";

                  createProductHandler({
                    name,
                    price,
                    mrp,
                    images,
                    sku,
                    brand,
                    status,
                    category,
                    countInStock,
                    description,
                    cost,
                    expiryDate,
                    tax: (price * taxpercentage) / 100,
                    size
                  });
                  return true;
                });

                console.log("---------------------------");
              }}
            >
              {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
              }) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      type="button"
                      {...getRootProps()}
                      style={{
                        width: "20%",
                      }}
                    >
                      Browse file
                    </button>
                    <div
                      style={{
                        border: "1px solid #ccc",
                        height: "50px",
                        paddingLeft: "10px",
                        width: "80%",
                        fontSize: "30px",
                      }}
                    >
                      {acceptedFile && acceptedFile.name}
                    </div>
                    <button
                      {...getRemoveFileProps()}
                      style={{
                        borderRadius: "0",
                        padding: "0 20px",
                        color: "white",
                        backgroundColor: "Red",
                      }}
                    >
                      Remove
                    </button>
                  </div>
                  <ProgressBar
                    style={{
                      backgroundColor: "lightBlue",
                    }}
                  />
                </>
              )}
            </CSVReader>
          </div>

          <>
            <Row className="tableHead mobileHiddenList listSize">
              <Col md={1}>Image</Col>
              <Col md={2}>NAME </Col>
              <Col md={1}>PRICE </Col>
              <Col md={2}>SKU</Col>
              <Col md={2}>CATEGORY </Col>
              <Col md={1}>BRAND </Col>
              <Col md={1}>STATUS </Col>
              <Col md={1}>EDIT</Col>
              <Col md={1}>DELETE</Col>
            </Row>

            <div className=" mobileHiddenList ">
              {products.map((product) => (
                <Row key={product._id}>
                  <Col md={1}>
                    <img
                      className="fitImageList"
                      src={
                        product.images[0]
                          ? product.images[0]
                          : "./img/sample.jpg"
                      }
                      alt="img"
                    />
                  </Col>
                  <Col className="listSize" md={2}>
                    {product.name}
                  </Col>
                  <Col className="listSize tableData" md={1}>
                    {product.price}
                  </Col>
                  <Col className="listSize tableData" md={2}>
                    {product.sku}
                  </Col>
                  <Col className="listSize tableData" md={2}>
                    {product.category}
                  </Col>
                  <Col className="listSize tableData" md={1}>
                    {product.brand}
                  </Col>
                  <Col className="tableData" md={1}>
                    {" "}
                    {product.status === "Published" ? (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i className="fas fa-times" style={{ color: "red" }}></i>
                    )}{" "}
                  </Col>
                  <Col className="tableData" md={1}>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                  </Col>
                  <Col className="tableData" md={1}>
                    <Button
                      variant="light"
                      style={{ color: "red" }}
                      className="btn-sm"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Col>
                </Row>
              ))}
            </div>
            <div className="mobileHidden">
              {products.map((item) => (
                <Col className="mb-2 border-bottom" sm={12} xs={12} md={4}>
                  <div key={item.product} style={{ maxWidth: "100%" }}>
                    <Row className="g-1">
                      <Col xs={3} style={{ display: "grid" }}>
                        <img
                          src={
                            item.images[0] ? item.images[0] : "./img/sample.jpg"
                          }
                          style={{ padding: "0.5rem" }}
                          className="img-fluid round-start fitImageCart"
                          alt={item.name}
                        />
                      </Col>
                      <Col xs={9}>
                        <div>
                          <p className="truncate">
                            {" "}
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>{" "}
                          </p>
                          <p>
                            <span style={{ color: "black" }}>
                              price: ₹ {item.price.toFixed(0)}
                            </span>{" "}
                            <button
                              style={{ marginRight: "2rem", float: "right" }}
                              id="delete"
                              type="button"
                              className="btn btn-sm "
                              onClick={() => deleteHandler(item._id)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                            <LinkContainer
                              style={{ marginRight: "2rem", float: "right" }}
                              to={`/admin/product/${item._id}/edit`}
                            >
                              <Button variant="light" className="btn-sm">
                                <i className="fas fa-edit"></i>
                              </Button>
                            </LinkContainer>{" "}
                          </p>
                          <p>
                            Mrp:
                            <span style={{ textDecoration: "line-through" }}>
                              ₹ {item.mrp.toFixed(0)}
                            </span>
                          </p>
                          <div>
                            Status:
                            <span style={{ marginLeft: "1.5rem" }}>
                              {item.status === "Published" ? (
                                <i
                                  className="fas fa-check"
                                  style={{ color: "green" }}
                                ></i>
                              ) : (
                                <i
                                  className="fas fa-times"
                                  style={{ color: "red" }}
                                ></i>
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </div>
          </>

          <Paginate pages={pages} page={page} productList={true} isAdmin={true} />
        </>
      )}
    </div>
  );
};

export default ProductListScreen;
