import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const LocationNotInService = () => {
  return (
    <div>
      <div className="btn btn-outline-primary mb-2 mt-3">
        <Link to="/location">
          <BiArrowBack style={{ marginBottom: "0.2rem" }} /> Go back
        </Link>
      </div>
      <div className="text-center">
        <h1 style={{ color: "black" }}>Location Not In Service</h1>
        <Image
          src={process.env.PUBLIC_URL + "/notInservice.png"}
          alt="Location Not In Service"
          fluid
        />
        <p style={{ color: "black" }}>
          Sorry, we are not in service ,in your area yet.
        </p>
      </div>
    </div>
  );
};

export default LocationNotInService;
