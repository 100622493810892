import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Overlay } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import axios from "axios";
import env from "dotenv";

env.config();

const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState("");
  const [result, setResult] = useState([]);

  const target = useRef(null);

  const storeConfig = useSelector((state) => state.storeConfig);

  const { store } = storeConfig;
  const storeId = store ? store._id : null;
  document.title = store ? store.name : null;

  useEffect(() => {
    if (keyword.trim()) {
      const searchHandler = setTimeout(() => {
        axios
          .get(`/api/marketplace/${storeId}/products/?keyword=${keyword}`)
          .then((res) => {
            let result = res.data.products;
            setResult(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 600);

      return () => {
        clearTimeout(searchHandler);
      };
    } else {
      setResult([]);
    }
  }, [keyword, storeId]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (keyword.trim()) {
      setResult([]);
      history.push(`/search/${keyword}`);
      setKeyword("");
    } else {
      history.push("/");
    }
  };

  const suggestHandler = (suggestion) => {
    if (suggestion.trim()) {
      history.push(`/search/${suggestion}`);
    } else {
      history.push("/");
    }
  };

  console.log(keyword);

  return (
    <Form onSubmit={submitHandler} inline>
      <Row>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          xs={10}
          md={11}
        >
          <div>
            <Form.Control
              ref={target}
              autoComplete="off"
              type="search"
              name="q"
              id="searchBox"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onBlur={() => {
                setTimeout(() => {
                  setResult([]);
                }, 100);
              }}
              placeholder="Search products"
              className="mr-sm-2 ml-sm-5"
            ></Form.Control>
          </div>
          <div>
            <Overlay
              target={target.current}
              show={true}
              placement="bottom-start"
            >
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <div
                  {...props}
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    zIndex: "10",
                    position: "absolute",
                    backgroundColor: "#fff",
                    color: "white",
                    borderRadius: 3,
                    ...props.style,
                  }}
                >
                  {result
                    .map((item) => (
                      <div
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          boxSizing: "border-box",
                          margin: "0.7rem",
                          width: "10.5em",
                        }}
                        key={item._id}
                        onClick={() => {
                          suggestHandler(item.name);
                        }}
                      >
                        {" "}
                        <IoIosSearch
                          size={17}
                          style={{ color: "#000", marginRight: "0.4rem" }}
                        />
                        {item.name}
                      </div>
                    ))
                    .slice(0, 5)}
                </div>
              )}
            </Overlay>
          </div>
        </Col>
        {window.innerWidth < 450 ? (
          <Col style={{ position: "relative", height: "2.4rem" }} xs={1} md={1}>
            <FaSearch
              type="submit"
              onClick={submitHandler}
              style={{ color: "white", fontSize: "20px" }}
            />
          </Col>
        ) : (
          <Col xs={1} md={1}>
            <button type="submit" className="white_button" id="search">
              Search
            </button>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default SearchBox;
